import { TableRow } from '@material-ui/core';
import React from 'react';

import TranslationTableCell, { TranslationCellData } from './TranslationTableCell';
import { TRANSLATION_COLUMNS } from './TranslationTableHead';

export interface TranslationRowData {
  [key: string]: TranslationCellData;
  english: TranslationCellData;
  translation: TranslationCellData;
  project: TranslationCellData;
}

interface Props {
  row: TranslationRowData;
}

export default React.memo((props: Props) => {
  return (
      <TableRow hover>
        {TRANSLATION_COLUMNS.map(column => (
            <TranslationTableCell key={column.name} cell={props.row[column.name]} width={column.width} />
        ))}
      </TableRow>
  );
});
