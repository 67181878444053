import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { createDarkTheme, createLightTheme } from '../constants/Themes';
import { RootState } from '../state/Store';
import Main from './Main';

interface StateProps {
  useDarkTheme: boolean;
}

type Props = StateProps;

function App(props: Props) {
  const theme = props.useDarkTheme ? createDarkTheme() : createLightTheme();
  useEffect(() => {
    document.querySelector('meta[name="color-scheme"]')!!.setAttribute('content', theme.palette.type);
    document.querySelector('meta[name="theme-color"]')!!.setAttribute('content', theme.palette.background.default);
    // eslint-disable-next-line
  }, [props.useDarkTheme]);
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main />
      </ThemeProvider>
  );
}

function mapState(state: RootState): StateProps {
  return {
    useDarkTheme: state.useDarkTheme
  };
}

export default connect(mapState)(App);
