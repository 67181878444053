import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

interface TranslationColumnData {
  name: string;
  label: string;
  width: string;
}

export const TRANSLATION_COLUMNS: Array<TranslationColumnData> = [
  {
    name: 'english',
    label: 'English',
    width: '40%',
  },
  {
    name: 'translation',
    label: 'Translation',
    width: '40%',
  },
  {
    name: 'project',
    label: 'Project',
    width: '20%',
  },
];

export default React.memo(() => {
  return (
      <TableHead>
        <TableRow>
          {TRANSLATION_COLUMNS.map(column => (
              <TableCell key={column.name} style={{ width: column.width }}>
                {column.label}
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
});
