import debounce from 'lodash.debounce';
import { IconButton, InputAdornment, SvgIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchOutlined } from '@material-ui/icons';
import { mdiRegex } from '@mdi/js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { fetchTranslations, setQuery, toggleRegularExpression } from '../state/Slices';
import { AppDispatch, RootState } from '../state/Store';
import { getQueryParameters } from '../utils/HistoryUtils';
import AppBarTextField from './AppBarTextField';

interface StateProps {
  useRegularExpression: boolean;
}

interface DispatchProps {
  onSubmit: (value: string) => void;
  onUseRegularExpressionClick: () => void;
}

type Props = StateProps & DispatchProps;

const useStyles = makeStyles(theme => ({
  useRegularExpressionColorSecondary: {
    color: theme.palette.text.hint,
  },
}));

function QueryTextField(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState(() => getQueryParameters().query || '');
  const debouncedOnSubmit = useCallback(debounce(props.onSubmit, 500), [props.onSubmit]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debouncedOnSubmit(event.target.value);
  }
  const handleUseRegularExpressionClick = () => props.onUseRegularExpressionClick()
  // Prevent focus on mouse click.
  const handleUseRegularExpressionMouseDown = (event: React.MouseEvent) => event.preventDefault();
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const target = event.target;
      if (target instanceof HTMLInputElement || target instanceof HTMLSelectElement
          || target instanceof HTMLTextAreaElement || (target instanceof HTMLElement && target.isContentEditable)
          || document.designMode === 'on') {
        return;
      }
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || event.isComposing || event.key !== '/') {
        return;
      }
      inputRef.current!!.focus();
    };
    document.addEventListener('keyup', listener);
    return () => document.removeEventListener('keyup', listener);
  }, [inputRef]);
  return (
      <AppBarTextField
          autoFocus fullWidth placeholder='Search' inputRef={inputRef} value={value} InputProps={{
            startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlined />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title='Regular expression'>
                    <IconButton
                        aria-label='Toggle regular expression'
                        color={props.useRegularExpression ? 'primary' : 'secondary'} edge='end'
                        classes={{ colorSecondary: classes.useRegularExpressionColorSecondary }}
                        onClick={handleUseRegularExpressionClick} onMouseDown={handleUseRegularExpressionMouseDown}>
                      <SvgIcon>
                        <path d={mdiRegex} transform='translate(-1, -1)' />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
            ),
      }} onChange={handleChange} />
  );
}

function mapState(state: RootState): StateProps {
  return {
    useRegularExpression: state.useRegularExpression,
  };
}

function mapDispatch(dispatch: AppDispatch): DispatchProps {
  return {
    onSubmit(value) {
      dispatch(setQuery(value));
      dispatch(fetchTranslations());
    },
    onUseRegularExpressionClick() {
      dispatch(toggleRegularExpression());
      dispatch(fetchTranslations());
    },
  }
}

export default connect(mapState, mapDispatch)(QueryTextField);
