import { IconButton, TableCell } from '@material-ui/core';
import { OpenInNewOutlined } from '@material-ui/icons';
import React from 'react';

export interface TranslationCellData {
  text: string;
  link: string;
  matches?: [number, number][];
}

interface Props {
  cell: TranslationCellData;
  width: string;
}

function highlightMatches(text: string, matches: [number, number][]): React.ReactNode {
  const nodes = new Array<React.ReactNode>();
  let lastIndex = 0;
  for (const [start, end] of matches) {
    if (lastIndex < start) {
      nodes.push(text.substring(lastIndex, start));
    }
    nodes.push(<b>{text.substring(start, end)}</b>);
    lastIndex = end;
  }
  if (lastIndex < text.length) {
    nodes.push(text.substring(lastIndex));
  }
  return nodes;
}

export default React.memo((props: Props) => {
  return (
      <TableCell style={{ width: props.width }}>
        {props.cell.matches ? highlightMatches(props.cell.text, props.cell.matches) : props.cell.text}
        <IconButton
            aria-label='View source' color='inherit' component='a' edge='end' href={props.cell.link} target='_blank'
            rel='noopener noreferrer'>
          <OpenInNewOutlined />
        </IconButton>
      </TableCell>
  );
});
