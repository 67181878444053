export function getLocalStorageItem(key: string): any | undefined {
  const item = localStorage.getItem(key);
  if (!item) {
    return undefined;
  }
  try {
    return JSON.parse(item);
  } catch (e) {
    return undefined;
  }
}

export function setLocalStorageItem(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}
