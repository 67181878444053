import { Table, TableBody } from '@material-ui/core';
import React from 'react';

import TranslationTableHead from './TranslationTableHead';
import TranslationTableRow, { TranslationRowData } from './TranslationTableRow';

// We cannot use JSS styling in components rendered in web worker, because generated class names may clash with the ones
// generated normally.
import './TranslationTable.css';

const emptyRow: TranslationRowData = {
  english: {
    text: '',
    link: '',
    matches: [],
  },
  translation: {
    text: '',
    link: '',
    matches: [],
  },
  project: {
    text: '',
    link: '',
  },
};

function TranslationTableStyles() {
  // Hidden table to ensure relevant Material-UI styles are loaded.
  return (
      <Table style={{ display: 'none' }}>
        <TranslationTableHead />
        <TableBody>
          <TranslationTableRow row={emptyRow} />
        </TableBody>
      </Table>
  );
}

export default React.memo(TranslationTableStyles);
