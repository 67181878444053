import { Box, CircularProgress, Typography } from '@material-ui/core';
import { TranslateOutlined } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../state/Store';
import TranslationTableStyles from './TranslationTableStyles';

interface StateProps {
  hasQuery: boolean;
  isFetching: boolean;
  html: string;
}

type Props = StateProps;

function StatefulTranslationTable(props: Props) {
  if (props.isFetching) {
    return (
        <Box flex={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <CircularProgress thickness={4} size={80} />
        </Box>
    );
  } else if (!props.html) {
    return (
        <Box flex={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <TranslateOutlined color='disabled' style={{ fontSize: 240 }} />
          <Typography color='textSecondary' variant='subtitle1'>
            {props.hasQuery ? 'No translations found' : 'Type to start searching'}
          </Typography>
        </Box>
    );
  } else {
    return (
        <>
          <TranslationTableStyles />
          <div dangerouslySetInnerHTML={{ __html: props.html }} />
        </>
    );
  }
}

function mapState(state: RootState): StateProps {
  return {
    hasQuery: !!state.query.length,
    isFetching: state.translations.isFetching,
    html: state.translations.html,
  }
}

export default connect(mapState)(StatefulTranslationTable);
