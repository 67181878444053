export const googleBlue = {
  300: '#8ab4f8',
  500: '#4285f4',
}

export const googleGrey = {
  50: '#f8f9fa',
  100: '#f1f3f4',
  200: '#e8eaed',
  300: '#dadce0',
  400: '#bdc1c6',
  500: '#9aa0a6',
  600: '#80868b',
  700: '#5f6368',
  800: '#3c4043',
  850: '#313235',
  900: '#202124',
}
