import Iso6391 from 'iso-639-1';
import Iso31661Alpha2 from 'iso-3166-1-alpha-2';

export function getLocaleName(locale: string): string {
  const match = locale.match(/^([a-z]{2})(?:-([A-Z]{2}))?$/);
  if (!match) {
    return locale;
  }
  const languageCode = match[1];
  const languageName = Iso6391.getName(languageCode);
  const countryCode = match[2];
  const countryName = (countryCode && Iso31661Alpha2.getCountry(countryCode)) || '';
  if (!countryName) {
    return languageName;
  } else {
    return `${languageName} (${countryName})`;
  }
}
