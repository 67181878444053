import { Box, Toolbar } from '@material-ui/core';
import React from 'react';

import MainAppBar from './MainAppBar';
import StatefulTranslationHeadTable from './StatefulTranslationHeadTable';
import StatefulTranslationTable from './StatefulTranslationTable';

export default function Main() {
  return (
      <Box flex={1} display='flex' flexDirection='column'>
        <MainAppBar>
          <StatefulTranslationHeadTable />
        </MainAppBar>
        <Toolbar />
        <StatefulTranslationTable />
      </Box>
  );
}
