import { MenuItem } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import locales from '../constants/Locales';
import { fetchTranslations, setLocale } from '../state/Slices';
import { AppDispatch, RootState } from '../state/Store';
import AppBarTextField from './AppBarTextField';

interface StateProps {
  superproject: string;
  value: string;
}

interface DispatchProps {
  onChange: (value: string) => void;
}

type Props = StateProps & DispatchProps;

function LocaleSelect(props: Props) {
  const useNativeSelect = isMobile;
  const SelectItem = useNativeSelect ? 'option' : MenuItem;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  }
  return (
      <AppBarTextField
          fullWidth placeholder='Language' select value={props.value} SelectProps={{ native: useNativeSelect }}
          onChange={handleChange}>
        {locales[props.superproject].map(locale => (
            <SelectItem key={locale.code} value={locale.code}>
              {locale.name}
            </SelectItem>
        ))}
      </AppBarTextField>
  );
}

function mapState(state: RootState): StateProps {
  return {
    superproject: state.superproject,
    value: state.locale,
  }
}

function mapDispatch(dispatch: AppDispatch): DispatchProps {
  return {
    onChange(value) {
      dispatch(setLocale(value));
      dispatch(fetchTranslations());
    }
  }
}

export default connect(mapState, mapDispatch)(LocaleSelect);
