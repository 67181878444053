export default [
  {
    id: 'android',
    name: 'Android',
  },
  {
    id: 'gnome',
    name: 'GNOME',
  },
];
