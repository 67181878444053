import { BaseTextFieldProps, FilledInputProps, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { googleGrey } from '../constants/Colors';

interface Props extends BaseTextFieldProps {
  onBlur?: FilledInputProps['onBlur'];
  onChange?: FilledInputProps['onChange'];
  onFocus?: FilledInputProps['onFocus'];
  InputProps?: Partial<FilledInputProps>;
  inputProps?: FilledInputProps['inputProps'];
}

const useStyles = makeStyles((theme: Theme) => {
  const isLightTheme = theme.palette.type === 'light';
  return createStyles({
    inputRoot: {
      backgroundColor: isLightTheme ? googleGrey[100] : googleGrey[850],
      borderRadius: 2 * theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: isLightTheme ? googleGrey[200] : googleGrey[800],
      },
      '&.Mui-focused': {
        backgroundColor: isLightTheme ? googleGrey[200] : googleGrey[800],
      },
      '&.Mui-disabled': {
        backgroundColor: isLightTheme ? googleGrey[50] : googleGrey[850],
      },
    },
    inputInput: {
      height: 24,
      paddingTop: 11,
      paddingBottom: 11,
      lineHeight: '24px',
    }
  });
});

export default function AppBarTextField(props: Props) {
  const classes = useStyles();
  return (
      <TextField
          {...props} hiddenLabel variant='filled' InputProps={{
            ...props.InputProps,
            disableUnderline: true,
            classes: {
              ...props.InputProps?.classes,
              root: classes.inputRoot,
              input: classes.inputInput,
            },
          }} inputProps={{
            'aria-label': props.placeholder,
            ...props.inputProps,
          }} />
  );
}
