import { IconButton } from '@material-ui/core';
import { Brightness4Outlined, Brightness5Outlined } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';

import { toggleDarkTheme } from '../state/Slices';
import { RootState } from '../state/Store';

interface StateProps {
  useDarkTheme: boolean;
}

interface DispatchProps {
  onClick: () => void;
}

type Props = StateProps & DispatchProps;

function DarkThemeButton(props: Props) {
  return (
      <IconButton aria-label={'Toggle dark theme'} color='inherit' edge='end' onClick={props.onClick}>
        {props.useDarkTheme ? <Brightness5Outlined /> : <Brightness4Outlined />}
      </IconButton>
  );
}

function mapState(state: RootState): StateProps {
  return {
    useDarkTheme: state.useDarkTheme,
  };
}

const mapDispatch: DispatchProps = {
  onClick: toggleDarkTheme,
}

export default connect(mapState, mapDispatch)(DarkThemeButton);
