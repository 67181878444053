import pickby from 'lodash.pickby';
import throttle from 'lodash.throttle';
import { configureStore } from '@reduxjs/toolkit';

import { getQueryParameters, setQueryParameters } from '../utils/HistoryUtils';
import { getLocalStorageItem, setLocalStorageItem } from '../utils/LocalStorageUtils';
import { fetchTranslations, rootReducer } from './Slices';

const STATE_KEY = 'translations-state';
const localStorageState = getLocalStorageItem(STATE_KEY);
let { query, useRegularExpression: useRegularExpressionString, locale, superproject } = getQueryParameters();
const useRegularExpression = useRegularExpressionString !== undefined ? useRegularExpressionString === true.toString()
    : undefined;
const queryParametersState = pickby({ query, useRegularExpression, locale, superproject },
        value => value !== undefined);
const preloadedState = Object.assign({}, localStorageState, queryParametersState);

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});
export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

store.subscribe(() => {
  const { query, useRegularExpression: useRegularExpressionBoolean, locale, superproject } = store.getState();
  const useRegularExpression = useRegularExpressionBoolean.toString()
  setQueryParameters({ query, useRegularExpression, locale, superproject });
});
store.subscribe(throttle(() => {
  const { query, useRegularExpression, translations, ...state } = store.getState();
  setLocalStorageItem(STATE_KEY, state);
}, 1000));

if (preloadedState.query) {
  store.dispatch(fetchTranslations());
}
