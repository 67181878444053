import { MenuItem } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import superprojects from '../constants/Superprojects';
import { fetchTranslations, setSuperproject } from '../state/Slices';
import { AppDispatch, RootState } from '../state/Store';
import AppBarTextField from './AppBarTextField';

interface StateProps {
  value: string;
}

interface DispatchProps {
  onChange: (value: string) => void;
}

type Props = StateProps & DispatchProps;

function SuperprojectSelect(props: Props) {
  const useNativeSelect = isMobile;
  const SelectItem = useNativeSelect ? 'option' : MenuItem;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  }
  return (
      <AppBarTextField
          fullWidth placeholder='Language' select value={props.value} SelectProps={{ native: useNativeSelect }}
          onChange={handleChange}>
        {superprojects.map(superproject => (
            <SelectItem key={superproject.id} value={superproject.id}>
              {superproject.name}
            </SelectItem>
        ))}
      </AppBarTextField>
  );
}

function mapState(state: RootState): StateProps {
  return {
    value: state.superproject,
  }
}

function mapDispatch(dispatch: AppDispatch): DispatchProps {
  return {
    onChange(value) {
      dispatch(setSuperproject(value));
      dispatch(fetchTranslations());
    }
  }
}

export default connect(mapState, mapDispatch)(SuperprojectSelect);
