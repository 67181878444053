import { getLocaleName } from '../utils/LocaleUtils';

export default Object.fromEntries(Object.entries({
  android: [
    'af',
    'am',
    'ar',
    'as',
    'az',
    'be',
    'bg',
    'bn',
    'bs',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-IN',
    'en-SG',
    'es',
    'et',
    'eu',
    'fa',
    'fi',
    'fr',
    'gl',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'is',
    'it',
    'ja',
    'ka',
    'kk',
    'km',
    'kn',
    'ko',
    'ky',
    'lo',
    'lt',
    'lv',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms',
    'my',
    'nb',
    'ne',
    'nl',
    'no',
    'or',
    'pa',
    'pl',
    'pt',
    'pt-BR',
    'pt-PT',
    'ro',
    'ru',
    'si',
    'sk',
    'sl',
    'sq',
    'sr',
    'sv',
    'sw',
    'ta',
    'te',
    'th',
    'tl',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'zh-CN',
    'zh-HK',
    'zh-TW',
    'zu',
  ],
  gnome: [
    'af',
    'ak',
    'am',
    'an',
    'ar',
    'as',
    'az',
    'be',
    'bg',
    'bn',
    'bo',
    'br',
    'bs',
    'ca',
    'ce',
    'cs',
    'cy',
    'da',
    'de',
    'dv',
    'dz',
    'el',
    'en-AU',
    'en-CA',
    'en-GB',
    'eo',
    'es',
    'et',
    'eu',
    'fa',
    'fi',
    'fr',
    'fy',
    'ga',
    'gd',
    'gl',
    'gn',
    'gu',
    'gv',
    'ha',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'ia',
    'id',
    'ig',
    'io',
    'is',
    'it',
    'ja',
    'ka',
    'kg',
    'kk',
    'km',
    'kn',
    'ko',
    'ks',
    'ku',
    'ky',
    'la',
    'li',
    'ln',
    'lo',
    'lt',
    'lv',
    'mg',
    'mi',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms',
    'my',
    'nb',
    'ne',
    'nl',
    'nn',
    'oc',
    'or',
    'os',
    'pa',
    'pl',
    'ps',
    'pt',
    'ro',
    'ru',
    'rw',
    'sd',
    'se',
    'si',
    'sk',
    'sl',
    'sq',
    'sr',
    'sv',
    'sw',
    'ta',
    'te',
    'tg',
    'th',
    'tk',
    'tr',
    'ts',
    'ug',
    'uk',
    'ur',
    'uz',
    'vi',
    'wa',
    'xh',
    'yi',
    'yo',
    'zh-CN',
    'zh-HK',
    'zh-TW',
    'zu',
  ],
}).map(([superproject, codes]) => [superproject, codes.map(code => ({ code, name: getLocaleName(code) }))]));
