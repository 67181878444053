import { AppBar, Box, Toolbar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import DarkThemeButton from './DarkThemeButton';
import ElevationOnScroll from './ElevateOnScroll';
import LocaleSelect from './LocaleSelect';
import QueryTextField from './QueryTextField';
import SuperprojectSelect from './SuperprojectSelect';

interface Props {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: theme.palette.background.default,
      },
    }),
);

export default React.memo((props: Props) => {
  const classes = useStyles();
  return (
      <ElevationOnScroll>
        <AppBar color='default' position='fixed' className={classes.appBar}>
          <Toolbar>
            <Box display='flex' flex={1}>
              <Box flex={2} maxWidth={720} mr={1}>
                <QueryTextField />
              </Box>
              <Box flex={1} maxWidth={240} mr={1}>
                <LocaleSelect />
              </Box>
              <Box flex={1} maxWidth={240} mr={1}>
                <SuperprojectSelect />
              </Box>
            </Box>
            <DarkThemeButton />
          </Toolbar>
          {props.children}
        </AppBar>
      </ElevationOnScroll>
  );
});
