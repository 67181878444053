import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import locales from '../constants/Locales';
import superprojects from '../constants/Superprojects';
import { fetchTranslationTable } from './FetchTranslationTable';

const useDarkThemeSlice = createSlice({
  name: 'useDarkTheme',
  initialState: window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)').matches : false,
  reducers: {
    toggleDarkTheme: state => !state,
  },
});
export const { toggleDarkTheme } = useDarkThemeSlice.actions;

const querySlice = createSlice({
  name: 'query',
  initialState: '',
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => action.payload,
  },
});
export const { setQuery } = querySlice.actions;

const useRegularExpressionSlice = createSlice({
  name: 'useRegularExpression',
  initialState: false,
  reducers: {
    toggleRegularExpression: state => !state,
  },
});
export const { toggleRegularExpression } = useRegularExpressionSlice.actions;

const initialSuperproject = superprojects[0].id;

const localeSlice = createSlice({
  name: 'locale',
  initialState: locales[initialSuperproject].some(locale => locale.code === navigator.language) ? navigator.language
      : 'zh-CN',
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => action.payload,
  },
});
export const { setLocale } = localeSlice.actions;

const superprojectSlice = createSlice({
  name: 'superproject',
  initialState: initialSuperproject,
  reducers: {
    setSuperproject: (state, action: PayloadAction<string>) => action.payload,
  },
});
export const { setSuperproject } = superprojectSlice.actions;

interface Translations {
  isFetching: boolean;
  html: string;
}
export const fetchTranslations = createAsyncThunk<string, void, {
  state: {
    [querySlice.name]: ReturnType<typeof querySlice.reducer>,
    [useRegularExpressionSlice.name]: ReturnType<typeof useRegularExpressionSlice.reducer>,
    [localeSlice.name]: ReturnType<typeof localeSlice.reducer>,
    [superprojectSlice.name]: ReturnType<typeof superprojectSlice.reducer>,
  },
}>('fetchTranslations', async (arg, thunkApi) => {
  const { query, useRegularExpression, locale, superproject } = thunkApi.getState();
  return await fetchTranslationTable(query, useRegularExpression, locale, superproject);
});
const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    isFetching: false,
    html: '',
  } as Translations,
  reducers: {},
  extraReducers: builder => builder
      .addCase(fetchTranslations.pending, () => ({
        isFetching: true,
        html: '',
      }))
      .addCase(fetchTranslations.fulfilled, (state, action) => ({
        isFetching: false,
        html: action.payload,
      })),
});

export const rootReducer = combineReducers({
  [useDarkThemeSlice.name]: useDarkThemeSlice.reducer,
  [querySlice.name]: querySlice.reducer,
  [useRegularExpressionSlice.name]: useRegularExpressionSlice.reducer,
  [localeSlice.name]: localeSlice.reducer,
  [superprojectSlice.name]: superprojectSlice.reducer,
  [translationsSlice.name]: translationsSlice.reducer,
});
