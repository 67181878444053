// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'workerize-loader!./FetchTranslationTable.worker';

let worker = null;

export async function fetchTranslationTable(query, useRegularExpression, locale, superproject) {
  if (worker) {
    worker.terminate();
  }
  if (!query) {
    return '';
  }
  worker = Worker();
  const table = await worker.fetchTranslationTable(query, useRegularExpression, locale, superproject);
  worker.terminate();
  return table;
}
