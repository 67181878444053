import { Table } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../state/Store';
import TranslationTableHead from './TranslationTableHead';

interface StateProps {
  hasTable: boolean;
}

type Props = StateProps;

function StatefulTranslationHeadTable(props: Props) {
  if (props.hasTable) {
    return (
        <Table aria-hidden='true'>
          <TranslationTableHead />
        </Table>
    );
  } else {
    return (
        <></>
    );
  }
}

function mapState(state: RootState): StateProps {
  return {
    hasTable: !!state.translations.html,
  };
}

export default connect(mapState)(StatefulTranslationHeadTable);
