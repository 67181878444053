export function getQueryParameters(): { [key: string]: string } {
  const url = new URL(window.location.href);
  return Object.fromEntries(url.searchParams);
}

export function setQueryParameters(queryParameters: { [key: string]: string }) {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  for (const name of Array.from(searchParams.keys())) {
    searchParams.delete(name);
  }
  for (const [name, value] of Object.entries(queryParameters)) {
    searchParams.set(name, value);
  }
  window.history.replaceState(null, document.title, url.toString());
}
